import * as React from 'react';

import logo from '@/assets/logo-white.svg';
import { Button, Link } from '@/components/Elements';
import { Head } from '@/components/Head';
import { useNavigate } from 'react-router-dom';

type LayoutProps = {
  children: React.ReactNode;
  title: string;
};

export const RegisterLayout = ({ children, title }: LayoutProps) => {
  const navigate = useNavigate();
  return (
    <>
      <Head title={title} />
      {children}
    </>
  );
};
