import * as React from 'react';

import logo from '@/assets/logo.svg';
import { Link } from '@/components/Elements';
import { Head } from '@/components/Head';

type LayoutProps = {
  children: React.ReactNode;
  title: string;
};

export const LoginLayout = ({ children, title }: LayoutProps) => {
  return (
    <>
      <Head title={title} />
      {children}
    </>
  );
};
