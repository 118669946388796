import { doc, getDoc, DocumentData } from '@firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import placeholder from '@/assets/placeholder.jpg';
import BackdropLoader from '@/components/BackdropLoader';
import { useAuth } from '@/lib/auth';
import { db } from '@/lib/firestore';

function EventDetail() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [event, setEvent] = useState<Event | DocumentData | null>();

  //   const { user } = useAuth();
  const setDocument = async (id: string) => {
    setLoading(true);
    const noteSnapshot = await getDoc(doc(db, 'events', id));
    if (noteSnapshot.exists()) {
      const eventData = noteSnapshot.data();
      setEvent(eventData);
    }
    setTimeout(() => {
      setLoading(false);
    }, 0);
  };

  useEffect(() => {
    if (id) {
      setDocument(id);
    } else {
      setLoading(false);
    }
  }, [id]);
  console.log(event, 'event');

  return (
    <>
      {loading ? (
        <BackdropLoader open={true} />
      ) : (
        <>
          <div className="row mt-4">
            <div className="col-md-3">
              <p>
                {' '}
                <img
                  src={event?.image ?? placeholder}
                  style={{
                    width: '160px',
                    height: '160px',
                    borderRadius: '500px',
                    objectFit: 'cover',
                  }}
                  onError={(e: any) => {
                    e.target.src = placeholder;
                  }}
                />
              </p>
            </div>
            <div className="col-md-9">
              <p>
                <span className="evntSpan">Title :</span>
                {event.title}
              </p>
              <p>
                <span className="evntSpan">Organizer :</span>
                {event.organizer}
              </p>
              <p>
                <span className="evntSpan">Description :</span>
                {event.description}
              </p>
              <p>
                <span className="evntSpan">Time :</span>
                {new Date(event?.date?.seconds * 1000).toLocaleTimeString()}
              </p>
              <p>
                <span className="evntSpan">Date :</span>
                {new Date(event?.date?.seconds * 1000).toLocaleDateString()}
              </p>
            </div>
          </div>
          <p>
            <span className="evntSpan">Regions :</span>
            <div className="row">
              {event.eventLocations.map((item, index) => (
                <div key={index} className="col-md-4 mkaeBordIN ">
                  <div className="row mkaeBordINN">
                    <p>
                      <span className="flic">Name : </span>
                      {item?.name}
                    </p>
                    <p>
                      <span className="flic">Address : </span>

                      {item?.address}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </p>
          {event?.members?.length > 0 && (
            <>
              <hr />
              <p>
                <span className="evntSpan">Joined Member</span>
              </p>
            </>
          )}
          <div className="row">
            {event?.members?.map((item: any, index: any) => (
              <>
                {item?.requestStatus == '1' ? (
                  <div className="col-md-2 " key={index}>
                    <div className="profBox">
                      <img
                        src={item?.medias[0] ?? placeholder}
                        style={{
                          width: '80px',
                          height: '80px',
                          borderRadius: '500px',
                          objectFit: 'cover',
                        }}
                        onError={(e: any) => {
                          e.target.src = placeholder;
                        }}
                      />
                      <span className="evntSpan"> {item.name}</span>
                    </div>
                  </div>
                ) : null}
              </>
            ))}
          </div>
        </>
      )}
    </>
  );
}

export default EventDetail;
