import { ExclamationIcon, InformationCircleIcon } from '@heroicons/react/outline';
import { TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import React, { useEffect, useState } from 'react';

import { Button } from '@/components/Elements/Button';
import { useDisclosure } from '@/hooks/useDisclosure';

export type ConfirmationDialogProps = {
  triggerButton: React.ReactElement;
  confirmButton?: React.ReactElement;
  deleteButton?: React.ReactElement;
  title: string;
  body?: string;
  bodyData?: boolean;
  cancelButtonText?: string;
  icon?: 'danger' | 'info';
  isDone?: boolean;
  onDataConfirm?: (data: string) => void; // Callback to handle data confirmation
};

export const ConfirmationDialog = ({
  triggerButton,
  confirmButton,
  deleteButton,
  title,
  body = '',
  bodyData = false,
  cancelButtonText = 'Cancel',
  icon = 'danger',
  isDone = false,
  onDataConfirm, // Callback function to handle data confirmation
}: ConfirmationDialogProps) => {
  const { close, open, isOpen } = useDisclosure();
  const [dataValues, setValues] = useState('');
  React.useEffect(() => {
    if (isDone) {
      close();
    }
  }, [isDone, close]);

  const trigger = React.cloneElement(triggerButton, {
    onClick: open,
  });

  const [disBtn, setDisBtn] = useState(false);

  useEffect(() => {
    if (bodyData) {
      if (dataValues === '') {
        setDisBtn(true);
      } else if (dataValues) {
        setDisBtn(false);
      }
    }
  }, [bodyData, dataValues]);
  const handleConfirm = () => {
    if (onDataConfirm) {
      onDataConfirm(dataValues); // Invoke callback with dataValues
    }
    close();
  };
  return (
    <>
      {trigger}
      <Dialog
        maxWidth="md"
        open={isOpen}
        onClose={close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <div className="d-flex dialog-width">
            {icon === 'danger' && (
              <div className="flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
              </div>
            )}
            {icon === 'info' && (
              <div className="flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                <InformationCircleIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
              </div>
            )}
            <div className="ms-3">
              <h4>
                <b>{title}</b>
              </h4>
              {body && <p>{body}</p>}
              <p>
                {bodyData ? (
                  <TextField
                    label="Give Reason"
                    variant="outlined"
                    type="text"
                    onChange={(e) => setValues(e.target.value)}
                  />
                ) : null}
              </p>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="px-4 pb-4 modalButnFiv">
          <Button variant="outline" onClick={close}>
            {cancelButtonText}
          </Button>
          {confirmButton && (
            <Button disabled={disBtn} onClick={handleConfirm}>
              {confirmButton}
            </Button>
          )}
          {deleteButton && (
            <Button className="deleteButton" disabled={disBtn} onClick={handleConfirm}>
              {deleteButton}
            </Button>
          )}
          {/* Added onClick handler */}
        </DialogActions>
      </Dialog>
    </>
  );
};
