import { Route, Routes } from 'react-router-dom';

import { Login } from './Login';
import { Register } from './Register';
import { ResetPassword } from './ResetPassword';

export const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route path="login/:role" element={<Login />} />
      <Route path="register" element={<Register />} />
      <Route path="register/:role" element={<Register />} />
      <Route path="reset" element={<ResetPassword />} />
      <Route path="reset/:role" element={<ResetPassword />} />
    </Routes>
  );
};
