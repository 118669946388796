import * as React from 'react';

import { Head } from '../Head';
import { Spinner } from '../Elements';

type ContentLayoutProps = {
  children: React.ReactNode;
  title: string;
  loading?: boolean;
};

export const ContentLayout = ({ children, title, loading = false }: ContentLayoutProps) => {
  return (
    <>
      <Head title={title} />
      {loading ? (
        <div className="w-full h-48 flex justify-center items-center">
          <Spinner size="lg" />
        </div>
      ) : (
        <> {children}</>
      )}
    </>
  );
};
