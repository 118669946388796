import Switch from '@mui/material/Switch';
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  DocumentData,
  updateDoc,
} from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const label = { inputProps: { 'aria-label': 'Switch demo' } };
import placeholder from '@/assets/placeholder.jpg';
import BackdropLoader from '@/components/BackdropLoader';
import {
  Spinner,
  Button,
  Table,
  Dialog,
  DialogTitle,
  ConfirmationDialog,
} from '@/components/Elements';
import { ContentLayout } from '@/components/Layout';
import { useAuth } from '@/lib/auth';
import { db } from '@/lib/firestore';
import { useNotificationStore } from '@/stores/notifications';

import { Subscription } from '../types';

export const SubscriptionList = () => {
  const { user } = useAuth();
  const userRole = user?.role;
  const [eventsss, setEvent] = useState<Subscription | DocumentData | null>();
  let event;
  const [loading, setLoading] = useState<boolean>(false);
  const [itemStatus, setItemStatus] = useState<boolean>(false);
  const [rooms, setRooms] = useState<Subscription[]>([]);
  const navigate = useNavigate();
  const { addNotification } = useNotificationStore();
  const rowsPerPageOptions = [10, 25, 50, 100];
  const [deleteLoader, setDeleteLoader] = useState<boolean>(false);

  const getRooms = async () => {
    setLoading(true);
    const roomsSnapshot = await getDocs(collection(db, 'subscription'));
    const roomList = roomsSnapshot.docs.map((doc) => doc.data() as Subscription);
    setRooms(roomList);
    setLoading(false);
  };

  const handleDelete = async (roomId: string) => {
    try {
      setDeleteLoader(true);
      const noteRef = doc(db, 'subscription', roomId!);
      await deleteDoc(noteRef);
      addNotification({
        type: 'success',
        title: 'Success',
        message: 'Subscription deleted successfully!',
      });
      setRooms((prevRooms) => prevRooms.filter((room) => room.id !== roomId));
    } catch (e: any) {
      addNotification({
        type: 'error',
        title: 'Failure',
        message: e.message ?? 'There was an error deleting the room!',
      });
    } finally {
      setDeleteLoader(false);
    }
  };

  useEffect(() => {
    getRooms();
  }, []);

  const setDocument = async (id: string) => {
    setLoading(true);
    const noteSnapshot = await getDoc(doc(db, 'subscription', id));
    if (noteSnapshot.exists()) {
      const eventData = noteSnapshot.data();
      setEvent(eventData);
      event = eventData;
    }
    setTimeout(() => {
      setLoading(false);
      handleSubmit(id);
    }, 0);
  };

  const handleSubmit = async (id: any) => {
    console.log(id, 'ddddddddddddddddddddddddddd');
    console.log(event, 'ddddddddddddddddddddddddddd');

    setLoading(true);
    try {
      const values = {};
      values.id = id;
      values.image = event?.image;
      values.amount = event?.amount;
      values.android_sku_code = event?.android_sku_code;
      values.direct_request = event?.direct_request;
      values.ios_sku_code = event?.ios_sku_code;
      values.status = event?.status === true ? false : true;
      values.swipe = event?.swipe;
      values.type = event?.type;

      const noteRef = doc(db, 'subscription', id);
      await updateDoc(noteRef, values);
      getRooms();
      setLoading(false);
    } catch (e: any) {
      addNotification({
        type: 'error',
        title: 'Failure',
        message: e.message ?? 'There was an error!',
      });
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   if (event) {
  //     handleSubmit(event?.id);
  //   }
  // }, [event]);

  if (loading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }
  return (
    <>
      {loading ? (
        <BackdropLoader open={true} />
      ) : (
        <>
          <ContentLayout title="Rooms">
            <Table
              data={rooms}
              columns={[
                {
                  title: 'Image',
                  field: 'image',
                  Cell({ entry: { image } }) {
                    return (
                      <img
                        src={image ?? placeholder}
                        style={{ width: '50px', height: '50px', objectFit: 'cover' }}
                        onError={(e: any) => {
                          e.target.src = placeholder;
                        }}
                      />
                    );
                  },
                },
                {
                  title: 'Title',
                  field: 'title',
                },
                {
                  title: 'Amount',
                  field: 'amount',
                },
                {
                  title: 'Type',
                  field: 'type',
                  Cell({ entry: { type } }) {
                    return (
                      <>
                        {type === 'in_app_purchase' ? (
                          <p className="dicsr">In App Purchase</p>
                        ) : (
                          <p className="dicsr">Subscription</p>
                        )}
                      </>
                    );
                  },
                },
                {
                  title: 'Status',
                  field: 'id',
                  Cell({ entry: { status, id } }) {
                    return (
                      <div>
                        <Switch
                          {...label}
                          defaultChecked={status}
                          onChange={() => setDocument(id)}
                        />
                      </div>
                    );
                  },
                },
                {
                  title: 'Actions',
                  field: 'id',
                  Cell({ entry: { id } }) {
                    return (
                      <div>
                        <>
                          <Button
                            className="me-2"
                            variant="outline"
                            onClick={() => navigate(`/app/edit-subscription/${id}`)}
                          >
                            <i className="fa fa-pencil" />
                          </Button>
                          <ConfirmationDialog
                            triggerButton={
                              <Button
                                tabIndex={-1}
                                className="icon me-2"
                                variant="outline"
                                title="Delete"
                              >
                                <i className="fa fa-trash" />
                              </Button>
                            }
                            confirmButton={
                              <Button
                                variant="primary"
                                className="expanded-btn actPop"
                                onClick={() => handleDelete(id)}
                                isLoading={deleteLoader}
                              >
                                Delete
                              </Button>
                            }
                            title="Delete Subcription"
                            icon="danger"
                            body="Are you sure you want to delete this subcription ?"
                          />
                        </>
                        <Button
                          className="me-2"
                          variant="outline"
                          onClick={() => navigate(`/app/subscription-detail/${id}`)}
                        >
                          <i className="fa-solid fa-eye"></i>
                        </Button>
                      </div>
                    );
                  },
                },
              ]}
              rowsPerPageOptions={rowsPerPageOptions}
            />
          </ContentLayout>
        </>
      )}
    </>
  );
};
