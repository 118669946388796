import { useNavigate } from 'react-router-dom';

import { Button } from '@/components/Elements';
import { ContentLayout } from '@/components/Layout';
import { useAuth } from '@/lib/auth';

import { RoomsList } from '../components/RoomsList';

export const Rooms = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const userRole = user?.role;

  return (
    <ContentLayout title="Rooms">
      <div className="m-3">
        <div className="d-flex align-content-center justify-content-between mb-3">
          <h3>Rooms</h3>
          {userRole === 'Admin' ? null : (
            <Button onClick={() => navigate(`/app/room/add-room`)}>Add Room</Button>
          )}
        </div>
        <RoomsList />
      </div>
    </ContentLayout>
  );
};
