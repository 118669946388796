import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import BackdropLoader from '@/components/BackdropLoader';
import { ContentLayout } from '@/components/Layout';
import { useAuth } from '@/lib/auth';
import { db } from '@/lib/firestore';
import { useNotificationStore } from '@/stores/notifications';

import NotifyAll from './NotifyAll';

import './Allnotification.css';

export const AllNotification = () => {
  const { user } = useAuth();
  const userRole = user?.role;

  const [notifications, setMessages] = React.useState([]);

  const getMessages = async () => {
    setLoading(true);
    const typesToRetrieve = [
      'userGenerated',
      'influencerRequest',
      'eventAccepted',
      'eventRejected',
      'roomAccepted',
      'roomRejected',
    ];
    const querySnapshot = await getDocs(
      query(collection(db, 'notifications'), where('type', 'in', typesToRetrieve))
    );
    const msgList = querySnapshot.docs.map((doc) => {
      const document = doc.data();
      return document;
    });
    setMessages(msgList);
    setLoading(false);
  };
  React.useEffect(() => {
    getMessages();
  }, []);

  const [selected, setSelected] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(9);

  const handleAdd = () => {
    setCount(count + 5);
  };
  const handleSub = () => {
    setCount(count - 5);
  };

  const handleSelect = (id: string) => {
    let currentSelected = [...selected];
    if (currentSelected.includes(id)) {
      currentSelected = currentSelected.filter((i) => i !== id);
    } else {
      currentSelected.push(id);
    }
    setSelected(currentSelected);
  };
  const selectAll = () => {
    if (notifications?.length === selected.length) {
      setSelected([]);
      return;
    }
    const currentSelected = notifications?.map((i) => i.id) ?? [];
    setSelected(currentSelected);
  };

  // const deleteSelected = async () => {
  //   setLoading(true);
  //   console.log(selected, 'selectedId');

  //   // await deleteSelectedNotifications(selected);
  //   getMessages();
  //   useNotificationStore.getState().addNotification({
  //     title: 'Success',
  //     type: 'info',
  //     message: 'Notifications deleted successfully!',
  //   });
  //   setSelected([]);
  //   setLoading(false);
  // };

  const deleteSelected = async () => {
    setLoading(true);

    const promises = selected.map(async (docId: any) => {
      const docRef = doc(db, 'notifications', docId);

      await deleteDoc(docRef);
    });

    await Promise.all(promises);

    getMessages();
    useNotificationStore.getState().addNotification({
      title: 'Success',
      type: 'info',
      message: 'Notifications deleted successfully!',
    });
    setSelected([]);
    setLoading(false);
  };

  const readSelected = async () => {
    setLoading(true);
    console.log(selected, 'selectedId');

    const promises = selected.map(async (docId: any) => {
      const docRef = doc(db, 'notifications', docId);
      const values = {
        isRead: true,
      };
      await updateDoc(docRef, values);
    });

    await Promise.all(promises);

    getMessages();
    useNotificationStore.getState().addNotification({
      title: 'Success',
      type: 'info',
      message: 'Notifications read successfully!',
    });
    setSelected([]);
    setLoading(false);
  };
  const navigate = useNavigate();

  return (
    <>
      <ContentLayout title="Notifications">
        <section className="container main-notify">
          {loading ? (
            <BackdropLoader open={true} />
          ) : (
            <div className="notification-box">
              <div className="row lin">
                <div className="col-12 col-md-4 noti">Notifications</div>
                <div className="col-12 col-md-8 toper">
                  {selected.length > 0 && (
                    <>
                      <button onClick={deleteSelected}>
                        Delete {selected ? selected.length : null}
                      </button>
                      <button onClick={readSelected}>
                        Read {selected ? selected.length : null}
                      </button>
                    </>
                  )}
                  {notifications?.length !== 0 && <button onClick={selectAll}>Select all</button>}
                </div>
              </div>
              <div className="notifies">
                {notifications?.length === 0 && <p>No new notifications found</p>}
                <ul>
                  {/* {notifications?.slice(0, count)?.map((item) => ( */}
                  {notifications?.map((item) => (
                    <li key={item.id}>
                      {userRole === 'Professional' ? (
                        <>
                          {item?.type === 'eventAccepted' && (
                            <button
                              className="notify-navigate-button"
                              onClick={() => navigate('/app/events')}
                            >
                              <NotifyAll
                                item={item}
                                selected={selected}
                                onSelectItem={handleSelect}
                              />
                            </button>
                          )}
                          {item?.type === 'eventRejected' && (
                            <button
                              className="notify-navigate-button"
                              onClick={() => navigate('/app/events')}
                            >
                              <NotifyAll
                                item={item}
                                selected={selected}
                                onSelectItem={handleSelect}
                              />
                            </button>
                          )}
                          {item?.type === 'roomAccepted' && (
                            <button
                              className="notify-navigate-button"
                              onClick={() => navigate('/app/rooms')}
                            >
                              <NotifyAll
                                item={item}
                                selected={selected}
                                onSelectItem={handleSelect}
                              />
                            </button>
                          )}
                          {item?.type === 'roomRejected' && (
                            <button
                              className="notify-navigate-button"
                              onClick={() => navigate('/app/rooms')}
                            >
                              <NotifyAll
                                item={item}
                                selected={selected}
                                onSelectItem={handleSelect}
                              />
                            </button>
                          )}
                        </>
                      ) : (
                        <>
                          {item?.type === 'userGenerated' && (
                            <button
                              className="notify-navigate-button"
                              onClick={() => navigate('/app/users')}
                            >
                              <NotifyAll
                                item={item}
                                selected={selected}
                                onSelectItem={handleSelect}
                              />
                            </button>
                          )}
                          {item?.type === 'influencerRequest' && (
                            <button
                              className="notify-navigate-button"
                              onClick={() => navigate('/property-project')}
                            >
                              <NotifyAll
                                item={item}
                                selected={selected}
                                onSelectItem={handleSelect}
                              />
                            </button>
                          )}
                          {item?.type === 'eventAccepted' && (
                            <button
                              className="notify-navigate-button"
                              onClick={() => navigate('/app/events')}
                            >
                              <NotifyAll
                                item={item}
                                selected={selected}
                                onSelectItem={handleSelect}
                              />
                            </button>
                          )}
                          {item?.type === 'eventRejected' && (
                            <button
                              className="notify-navigate-button"
                              onClick={() => navigate('/app/events')}
                            >
                              <NotifyAll
                                item={item}
                                selected={selected}
                                onSelectItem={handleSelect}
                              />
                            </button>
                          )}
                          {item?.type === 'roomAccepted' && (
                            <button
                              className="notify-navigate-button"
                              onClick={() => navigate('/app/rooms')}
                            >
                              <NotifyAll
                                item={item}
                                selected={selected}
                                onSelectItem={handleSelect}
                              />
                            </button>
                          )}
                          {item?.type === 'roomRejected' && (
                            <button
                              className="notify-navigate-button"
                              onClick={() => navigate('/app/rooms')}
                            >
                              <NotifyAll
                                item={item}
                                selected={selected}
                                onSelectItem={handleSelect}
                              />
                            </button>
                          )}
                        </>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
              {/* {notifications?.data?.length !== 0 && (
                <div className="notifyBottom row">
                  {count <= 11 ? (
                    <>
                      {count > notifications?.data?.length ? null : (
                        <div>
                          <Button onClick={() => handleAdd()}>Show More</Button>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <div className="col-md-6">
                        {count <= 11 ? null : (
                          <Button onClick={() => handleSub()}>Show Less</Button>
                        )}
                      </div>
                      <div className="col-md-6">
                        {count >= notifications?.data?.length ? null : (
                          <Button onClick={() => handleAdd()}>Show More</Button>
                        )}
                      </div>
                    </>
                  )}
                </div>
              )} */}
            </div>
          )}
        </section>
        <br />
      </ContentLayout>
    </>
  );
};
