import { AppProvider } from '@/providers/app';
import { AppRoutes } from '@/routes';
import { useEffect } from 'react';

import { useAuth } from './lib/auth';

function App() {
  
  return (
    <AppProvider>
      <AppRoutes />
    </AppProvider>
  );
}

export default App;
