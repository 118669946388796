import { doc, getDoc, DocumentData } from '@firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import placeholder from '@/assets/placeholder.jpg';
import BackdropLoader from '@/components/BackdropLoader';
import { db } from '@/lib/firestore';

import { Subscription } from '../types';

function SubscriptionDetail() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [event, setEvent] = useState<Subscription | DocumentData | null>();

  //   const { user } = useAuth();
  const setDocument = async (id: string) => {
    setLoading(true);
    const noteSnapshot = await getDoc(doc(db, 'subscription', id));
    if (noteSnapshot.exists()) {
      const eventData = noteSnapshot.data();
      setEvent(eventData);
    }
    setTimeout(() => {
      setLoading(false);
    }, 0);
  };

  useEffect(() => {
    if (id) {
      setDocument(id);
    } else {
      setLoading(false);
    }
  }, [id]);

  return (
    <>
      {loading ? (
        <BackdropLoader open={true} />
      ) : (
        <>
          <div className="row mt-4">
            <div className="col-md-3">
              <p>
                {' '}
                <img
                  src={event?.image ?? placeholder}
                  style={{
                    width: '160px',
                    height: '160px',
                    borderRadius: '500px',
                    objectFit: 'cover',
                  }}
                  onError={(e: any) => {
                    e.target.src = placeholder;
                  }}
                />
              </p>
            </div>
            <div className="col-md-9">
              <p>
                <span className="evntSpan">Title</span>
                {event?.title}
              </p>
              <p>
                <span className="evntSpan">Amount</span>
                {event?.amount}
              </p>
              <p>
                <span className="evntSpan">Description</span>
                {event?.description}
              </p>
              <p>
                <span className="evntSpan">Type</span>
                {event?.type === 'in_app_purchase' ? 'In App Purchase' : 'Subscription'}
              </p>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default SubscriptionDetail;
