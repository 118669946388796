import { useNavigate } from 'react-router-dom';

import { Button } from '@/components/Elements';
import { ContentLayout } from '@/components/Layout';
import { useAuth } from '@/lib/auth';

import { SubscriptionList } from '../components/SubscriptionList';

export const Subscription = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const userRole = user?.role;

  return (
    <ContentLayout title="Rooms">
      <div className="m-3">
        <div className="d-flex align-content-center justify-content-between mb-3">
          <h3>Subscription list </h3>
          <Button onClick={() => navigate(`/app/add-subscription`)}>Add Subscription</Button>
        </div>
        <SubscriptionList />
      </div>
    </ContentLayout>
  );
};
