import axios from 'axios';
import { GeoPoint, doc, getDoc, getDocs, updateDoc, DocumentData, setDoc, collection } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { useState, useEffect, useRef } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';

import placeholder from '@/assets/plus.png';
import { Button, Spinner } from '@/components/Elements';
import { Form, InputField, SelectField, TextAreaField } from '@/components/Form';
import { ContentLayout } from '@/components/Layout';
import { useSelectFile } from '@/hooks/useSelectFile';
import { useAuth } from '@/lib/auth';
import { db, storage } from '@/lib/firestore';
import { useNotificationStore } from '@/stores/notifications';

import { Room } from '../types';

const schema = z.object({
  title: z.string().min(1, 'Title is required'),
  description: z.string().min(1, 'Description id required'),
  selectedCountry: z.string().min(1, 'Selected Country is required'),
  roomType: z.string().min(1, 'Room type is required'),
});

type City = {
  name: string;
}

export const AddRoom = () => {
  const { id } = useParams();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const { addNotification } = useNotificationStore();
  const [event, setEvent] = useState<Room | DocumentData | null>();
  const { file, preview, handleChange, setPicturePreview } = useSelectFile();
  const inputFileRef = useRef();

  const [locationGoogle, setLocationGoogle] = useState();
  const [value, setValue] = useState(null);
  const [address, setAddress] = useState<string>('');
  const [locationObj, setlocationObj] = useState();
  const [lat, setLat] = useState('');
  const [long, setLong] = useState('');
  const [city, setCity] = useState('');
  const [cities, setCities] = useState<string[]>([]);
  const [country, setCountry] = useState('');

  // ---------------------- Map Data -----------------------
  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAP_KEY;

  const handleChangeLocation = async (value: any) => {
    const placeId = value.value.place_id;
    const { data } = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?place_id=${placeId}&key=${googleMapsApiKey}`
    );
    console.log(data, 'data');

    const location = data?.results[0]?.geometry?.location;
    const addressData = data?.results[0]?.formatted_address;

    const addressComponents = data.results[0].address_components;
    let city = '';
    let country = '';
    for (let i = 0; i < addressComponents.length; i++) {
      const component = addressComponents[i];
      if (
        component.types.includes('locality') ||
        component.types.includes('administrative_area_level_1')
      ) {
        city = component.long_name;
        break;
      }
    }
    for (let i = 0; i < addressComponents.length; i++) {
      const component = addressComponents[i];
      if (component.types.includes('country')) {
        country = component.long_name;
        break;
      }
    }
    setCity(city);
    setCountry(country);

    if (location) {
      setLocationGoogle(location);
      setValue(value);
    }
    if (addressData) {
      setAddress(addressData);
    }
  };

  // ---------------- Map Data -----------------------

  const uploadImage = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const storageRef = ref(storage, `files/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        },
        (error) => {
          reject(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            resolve(downloadURL);
          });
        }
      );
    });
  };

  const handleSubmit = async (values: Room) => {
    const range = values.roomType === "Party" ? 100 : 500;
    setBtnLoading(true);
    try {
      let msg = '';
      if (file) {
        const image = await uploadImage(file);
        values.image = image;
      }
      if (values.description === '' || values.title === '') {
        addNotification({
          type: 'info',
          title: 'Information',
          message: 'Enter valid values',
        });
        return;
      }
      if (id) {
        const geoPoint = new GeoPoint(
          locationGoogle?.lat ? locationGoogle?.lat : lat,
          locationGoogle?.lng ? locationGoogle?.lng : long
        );
        values.address = address;
        values.location = geoPoint;
        values.region = city;
        values.country = country;
        values.range = range;
        const noteRef = doc(db, 'rooms', id);
        await updateDoc(noteRef, values);
        msg = 'Room updated successfully!';
      } else {
        if (!locationGoogle) {
          addNotification({
            type: 'info',
            title: 'Information',
            message: 'Please enter location',
          });
          return;
        }
        const uuid = uuidv4();
        let userRef;
        if (user?.role === 'Admin') {
          userRef = doc(db, 'admins', user?.id);
        } else if (user?.role === 'Professional') {
          userRef = doc(db, 'professionals', user?.id);
        }
        const geoPoint = new GeoPoint(
          locationGoogle?.lat ? locationGoogle?.lat : lat,
          locationGoogle?.lng ? locationGoogle?.lng : long
        );
        values.location = geoPoint;
        values.address = address;
        values.createdBy = userRef;
        values.range = range;
        values.region = city;
        values.country = country;
        values.id = uuid;
        await setDoc(doc(db, 'rooms', uuid), values);
        msg = 'Room created successfully!';
      }
      addNotification({
        type: 'success',
        title: 'Success',
        message: msg,
      });
      navigate('/app/rooms');
      setBtnLoading(false);
    } catch (e: any) {
      addNotification({
        type: 'error',
        title: 'Failure',
        message: e.message ?? 'There was an error!',
      });
    } finally {
      setBtnLoading(false);
    }
  };

  useEffect(() => {
    if (event) {
      setlocationObj(event?.location);
      setAddress(event.address);
    }
  }, [event]);

  useEffect(() => {
    if (locationObj) {
      console.log(locationObj, 'locationObj');
      setLat(locationObj?._lat);
      setLong(locationObj?._long);
    }
  }, [locationObj]);

  useEffect(() => {
    if (locationObj) {
      setLat(locationObj?._lat);
      setLong(locationObj?._long);
    }
  }, [locationObj]);

  const getCities = async () => {
    const noteSnapshotCity = await getDocs(collection(db, 'regions'));
    const cityList = noteSnapshotCity.docs.map((doc) => doc.data() as City);
    const cityNames = cityList.map(i => i.name);
    setCities(cityNames);
  }
  
  const setDocument = async (id: string) => {
    setLoading(true);

    await getCities();
    const noteSnapshot = await getDoc(doc(db, 'rooms', id));
    if (noteSnapshot.exists()) {
      const eventData = noteSnapshot.data();
      setEvent(eventData);
      setPicturePreview(eventData.image ?? '');
    }
    setTimeout(() => {
      setLoading(false);
    }, 0);
  };

  const pageLoad = async (id) => {
    if (id) {
      setDocument(id);
    } else {
      await getCities();
      setLoading(false);
    }
  }

  useEffect(() => {
    pageLoad(id);
  }, [id]);

  return (
    <ContentLayout title="Create Room">
      {loading ? (
        <div className="w-full h-48 flex justify-center items-center">
          <Spinner size="lg" />
        </div>
      ) : (
        <div className="m-3">
          <div className="d-flex align-content-center justify-content-between mb-3">
            {id ? <h3>Edit Room</h3> : <h3>Create Rooms</h3>}
          </div>
          <Form
            onSubmit={handleSubmit}
            className="row g-3 needs-validation"
            schema={schema}
            options={{ defaultValues: { ...event, range: event?.range?.toString() } }}
          >
            {({ register, formState }) => (
              <>
                <div className="row mt-2">
                  <div className="flex gap-4 items-center">
                    {preview ? (
                      <img
                        alt="placeholder"
                        src={preview}
                        style={{
                          height: '100px',
                          width: '100px',
                          borderRadius: '50%',
                          objectFit: 'cover',
                          border: '1px solid grey',
                          cursor: 'pointer',
                        }}
                        className="previewImage"
                        onClick={() => inputFileRef?.current.click()}
                      />
                    ) : (
                      <img
                        src={event?.image ?? placeholder}
                        alt=""
                        style={{
                          height: '100px',
                          width: '100px',
                          objectFit: 'cover',
                          cursor: 'pointer',
                        }}
                        className="previewImage"
                        onClick={() => inputFileRef?.current.click()}
                      />
                    )}
                    <div className="col-6 mt-4">
                      <input
                        type="file"
                        id="fileInput"
                        ref={inputFileRef ?? ''}
                        onChange={handleChange}
                        accept="image/*"
                        style={{ display: 'none' }}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-3 mt-4">
                      <InputField
                        type="text"
                        label="Title"
                        error={formState.errors['title']}
                        registration={register('title')}
                      />
                    </div>
                    {/* <div className="col-3 mt-4">
                      <InputField
                        type="number"
                        label="Range in meters"
                        error={formState.errors['range']}
                        registration={register('range', { value: '3000' })}
                      />
                    </div> */}
                    <div className="col-3 mt-4">
                      <SelectField
                        label="Country"
                        options={cities.map(i => ({ label: i, value: i }))}
                        error={formState.errors['selectedCountry']}
                        registration={register('selectedCountry')}
                      />
                    </div>
                    <div className="col-3 mt-4 locationDivss">
                      <p className="locs">Location</p>
                      <GooglePlacesAutocomplete
                        apiKey={googleMapsApiKey}
                        selectProps={{
                          defaultInputValue: address ? address : '',
                          value,
                          isClearable: true,
                          placeholder: 'Search location.',
                          onChange: handleChangeLocation,
                        }}
                      />
                    </div>
                    <div className="col-3 mt-4">
                      <SelectField
                        label="Room type"
                        options={[
                          { label: 'Party', value: 'Party' },
                          { label: 'University', value: 'University' },
                        ]}
                        error={formState.errors['roomType']}
                        registration={register('roomType')}
                      />
                    </div>
                  </div>
                  <div className="col-12 mt-4">
                    <TextAreaField
                      rows={3}
                      label="Description"
                      error={formState.errors['description']}
                      registration={register('description')}
                    />
                  </div>
                </div>

                <div className="d-flex justify-content-end">
                  <Button isLoading={btnLoading} type="submit" className="subBtn pada">
                    <span className="loffro">Submit</span>
                  </Button>
                </div>
              </>
            )}
          </Form>
        </div>
      )}
    </ContentLayout>
  );
};
