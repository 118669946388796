import clsx from 'clsx';
import moment from 'moment';
import React from 'react';

import { NotificationDTO } from '@/types/Notification';

const NotifyAll = ({
  item,
  selected,
  onSelectItem,
}: {
  item: NotificationDTO;
  selected: string[];
  onSelectItem: (item: string) => void;
}) => {
  const handleDate = (dates: any) => {
    // Convert the given date to milliseconds
    const givenDateMilliseconds = dates.seconds * 1000 + Math.round(dates.nanoseconds / 1e6);

    // Get the current date
    const currentDate = moment();

    // Calculate the difference between the current date and the given date
    const timeDifference = moment.duration(currentDate.diff(moment(givenDateMilliseconds)));

    // Get the elapsed time in days and hours
    const days = timeDifference.days();
    const hours = timeDifference.hours();
    const minutes = timeDifference.minutes();

    if (days) {
      return `${days} days ago`;
    } else if (hours) {
      return `${hours} hours ago`;
    } else {
      return `${minutes} min ago`;
    }
  };

  const handleClick = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
  };
  return (
    <>
      <div className={clsx('row notification', item.isRead == false ? 'unread' : 'read')}>
        <div className="col-md-2 row">
          <div className="setWidth col-3 col-md-4">
            <input
              checked={selected.includes(item.id)}
              type="checkbox"
              onChange={() => onSelectItem(item.id)}
              onClick={handleClick}
              className="form-check-input"
            />
          </div>
          <div className="setWidthdot col-2 col-md-2">
            {item.isRead == false ? <i className="fa-solid fa-circle notificationDot"></i> : null}
          </div>
        </div>
        <div className="col-12 col-md-3 cntnt">{item.title}</div>
        <div className="col-12 col-md-5 cntnt">{item.content}</div>
        <div className="col-12 col-md-2 cntnt">
          {item?.date && <p className="dateTimeP">{handleDate(item.date)}</p>}
        </div>
      </div>
    </>
  );
};

export default NotifyAll;
