import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { doc, getDoc, updateDoc, DocumentData, setDoc } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';

import placeholder from '@/assets/plus.png';
import { Button, Spinner } from '@/components/Elements';
import { Form, InputField, TextAreaField } from '@/components/Form';
import { ContentLayout } from '@/components/Layout';
import { useSelectFile } from '@/hooks/useSelectFile';
import { useAuth } from '@/lib/auth';
import { db, storage } from '@/lib/firestore';
import { useNotificationStore } from '@/stores/notifications';

import { Subscription } from '../types';

const schema = z.object({
  title: z.string().min(1, 'Title is required'),
  amount: z.string().min(1, 'Title is required'),
  description: z.string().min(1, 'Description id required'),
  android_sku_code: z.string().min(1, 'Android Sku ID is required'),
  ios_sku_code: z.string().min(1, 'Ios Sku Id is required'),
  swipe: z
    .string()
    .min(1)
    .regex(/^(?:[0-9]\d*|0)$/, 'Please enter valid numbers only')
    .refine((data: any) => data.memberCount !== '0', {
      message: 'Swipe should not be zero',
    }),
  direct_request: z
    .string()
    .min(1)
    .regex(/^(?:[0-9]\d*|0)$/, 'Please enter valid numbers only')
    .refine((data: any) => data.memberCount !== '0', {
      message: 'Direct request should not be zero',
    }),
});

export const AddSubscription = () => {
  const { id } = useParams();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const { addNotification } = useNotificationStore();
  const [event, setEvent] = useState<Subscription | DocumentData | null>();
  const { file, preview, handleChange, setPicturePreview } = useSelectFile();
  const inputFileRef = useRef();

  const [stype, setSubscriptionType] = useState('subscription');

  const handleTypeChange = (event: SelectChangeEvent) => {
    setSubscriptionType(event.target.value as string);
  };

  const uploadImage = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const storageRef = ref(storage, `files/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        },
        (error) => {
          reject(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            resolve(downloadURL);
          });
        }
      );
    });
  };

  const handleSubmit = async (values: Subscription) => {
    setBtnLoading(true);
    try {
      let msg = '';
      if (file) {
        const image = await uploadImage(file);
        values.image = image;
      }
      if (values.description === '' || values.title === '' || values.amount === null) {
        addNotification({
          type: 'info',
          title: 'Information',
          message: 'Enter valid values',
        });

        return;
      }
      if (id) {
        values.id = event?.id;
        values.direct_request = parseInt(values.direct_request);
        values.android_sku_code;
        values.amount;
        values.ios_sku_code;
        values.status = event?.status;
        values.swipe = parseInt(values.swipe);
        values.type = stype;

        const noteRef = doc(db, 'subscription', id);
        await updateDoc(noteRef, values);
        msg = 'Subscription updated successfully!';
      } else {
        const uuid = uuidv4();

        values.id = uuid;
        values.direct_request = parseInt(values.direct_request);
        values.status = true;
        values.swipe = parseInt(values.swipe);
        values.type = stype;
        await setDoc(doc(db, 'subscription', uuid), values);
        msg = 'Subscription created successfully!';
      }
      addNotification({
        type: 'success',
        title: 'Success',
        message: msg,
      });
      navigate('/app/subscription-list');
      setBtnLoading(false);
    } catch (e: any) {
      addNotification({
        type: 'error',
        title: 'Failure',
        message: e.message ?? 'There was an error!',
      });
    } finally {
      setBtnLoading(false);
    }
  };

  const setDocument = async (id: string) => {
    setLoading(true);
    const noteSnapshot = await getDoc(doc(db, 'subscription', id));
    if (noteSnapshot.exists()) {
      const eventData = noteSnapshot.data();
      setEvent(eventData);
      setPicturePreview(eventData.image ?? '');
    }
    setTimeout(() => {
      setLoading(false);
    }, 0);
  };

  useEffect(() => {
    if (id) {
      setDocument(id);
    } else {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    if (event) {
      setSubscriptionType(event?.type);
    }
  }, [event]);

  return (
    <ContentLayout title="Create Room">
      {loading ? (
        <div className="w-full h-48 flex justify-center items-center">
          <Spinner size="lg" />
        </div>
      ) : (
        <div className="m-3">
          <div className="d-flex align-content-center justify-content-between mb-3">
            {id ? <h3>Edit Subsrciption</h3> : <h3>Create Subsrciption</h3>}
          </div>
          <Form
            onSubmit={handleSubmit}
            className="row g-3 needs-validation"
            schema={schema}
            options={{
              defaultValues: {
                ...event,
                swipe: event?.swipe?.toString(),
                direct_request: event?.direct_request?.toString(),
              },
            }}
          >
            {({ register, formState }) => (
              <>
                <div className="row mt-2">
                  <div className="flex gap-4 items-center">
                    {preview ? (
                      <img
                        alt="placeholder"
                        src={preview}
                        style={{
                          height: '100px',
                          width: '100px',
                          borderRadius: '50%',
                          objectFit: 'cover',
                          border: '1px solid grey',
                          cursor: 'pointer',
                        }}
                        className="previewImage"
                        onClick={() => inputFileRef?.current.click()}
                      />
                    ) : (
                      <img
                        src={event?.image ?? placeholder}
                        alt=""
                        style={{
                          height: '100px',
                          width: '100px',
                          objectFit: 'cover',
                          cursor: 'pointer',
                        }}
                        className="previewImage"
                        onClick={() => inputFileRef?.current.click()}
                      />
                    )}
                    <div className="col-6 mt-4">
                      <input
                        type="file"
                        id="fileInput"
                        ref={inputFileRef ?? ''}
                        onChange={handleChange}
                        style={{ display: 'none' }}
                        accept="image/*"
                      />
                    </div>
                  </div>
                  <div className="col-4 mt-4">
                    <InputField
                      type="text"
                      label="Title"
                      error={formState.errors['title']}
                      registration={register('title')}
                    />
                  </div>
                  <div className="col-4 mt-4 locationDivss">
                    <InputField
                      type="text"
                      label="Amount"
                      error={formState.errors['amount']}
                      registration={register('amount')}
                    />
                  </div>
                  <div className="col-4 mt-5">
                    {/* <Box sx={{ width: 150 }}> */}
                    <Box>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Subscription Type</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={stype}
                          label="Select User Type"
                          onChange={handleTypeChange}
                        >
                          <MenuItem value={'in_app_purchase'}>In App Purchase</MenuItem>
                          <MenuItem value={'subscription'}>Subscription</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </div>

                  <div className="row mt-4">
                    <div className="col-md-6">
                      <InputField
                        type="text"
                        label="Android SKU Code"
                        error={formState.errors['android_sku_code']}
                        registration={register('android_sku_code')}
                      />
                    </div>
                    <div className="col-md-6">
                      <InputField
                        type="text"
                        label="ISO SKU ID"
                        error={formState.errors['ios_sku_code']}
                        registration={register('ios_sku_code')}
                      />
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-6">
                      <InputField
                        type="text"
                        label="Swipe"
                        error={formState.errors['swipe']}
                        registration={register('swipe')}
                      />
                    </div>
                    <div className="col-md-6">
                      <InputField
                        type="text"
                        label="Direct Request"
                        error={formState.errors['direct_request']}
                        registration={register('direct_request')}
                      />
                    </div>
                  </div>
                  <div className="col-12 mt-4">
                    <TextAreaField
                      rows={3}
                      label="Description"
                      error={formState.errors['description']}
                      registration={register('description')}
                    />
                  </div>
                </div>

                <div className="d-flex justify-content-end">
                  <Button isLoading={btnLoading} type="submit" className="subBtn pada">
                    <span className="loffro">Submit</span>
                  </Button>
                </div>
              </>
            )}
          </Form>
        </div>
      )}
    </ContentLayout>
  );
};
