import { uuidv4 } from '@firebase/util';
import { collection, query, where, getDocs, doc, updateDoc, setDoc } from 'firebase/firestore';
import { useEffect, useState } from 'react';

import placeholder from '@/assets/placeholder.jpg';
import { Spinner, Button, Table, ConfirmationDialog } from '@/components/Elements';
import { axios } from '@/lib/axios';
import { db } from '@/lib/firestore';
import { useNotificationStore } from '@/stores/notifications';

import { User } from '../types';
const serverKey = process.env.REACT_APP_FIREBASE_SERVER_KEY;

export const InfluencerList = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [contentVal, setContent] = useState('');
  const [typeVal, setType] = useState('');
  const [titelVal, setTitel] = useState('');
  const [load, setLoad] = useState<boolean>(false);
  const [users, setUsers] = useState<User[]>([]); // Adjust the type to match your User type
  const rowsPerPageOptions = [10, 25, 50, 100];
  const { addNotification } = useNotificationStore();

  const getUsers = async () => {
    setLoading(true);
    const q = query(
      collection(db, 'users'),
      where('influencerStatus', 'in', ['requested', 'accepted', 'rejected'])
    );
    const usersSnapshot = await getDocs(q);
    const userList = usersSnapshot.docs.map((doc) => doc.data() as User); // Adjust the type
    setUsers(userList);
    setLoading(false);
  };
  console.log(users, 'users');

  useEffect(() => {
    getUsers();
  }, []);

  if (loading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }
  const handleInfluencer = async (
    userId: string,
    status: string,
    obj: any,
    fcmToken: any,
    reason?: string
  ) => {
    try {
      console.log(reason, 'fffffffreasonfffffffff');
      console.log(obj, 'obj');

      let msg = '';
      let valuesNotify;
      const uuid = uuidv4();
      setLoad(true);
      const id = userId;
      const values = {
        influencerStatus: '',
        isInfluencer: false,
        requestRejectReason: '',
      };

      if (status === 'accepted') {
        valuesNotify = {
          id: uuid,
          content: 'Your request to become influencer is accepted!',
          isRead: false,
          data: {},
          title: 'Influencer Request Accepted',
          type: 'influencerAccepted',
          userId: id,
        };
        values.influencerStatus = 'accepted';
        if (!values.influencerDetails) {
          values.influencerDetails = {};
        }
        values.influencerDetails.status = 'accepted';
        values.influencerDetails.fullName = obj.fullName;
        values.influencerDetails.id = obj.id;
        values.influencerDetails.instaEmail = obj.instaEmail;
        values.influencerDetails.whatsappNumber = obj.whatsappNumber;
        values.isInfluencer = true;
        msg = 'Influencer Request accepted successfully!';
      } else if (status === 'rejected') {
        valuesNotify = {
          id: uuid,
          content: 'Your request to become influencer is rejected!',
          isRead: false,
          data: {},
          title: 'Influencer Request Rejected',
          type: 'influencerRejected',
          userId: id,
        };

        values.influencerStatus = 'rejected';
        values.isInfluencer = false;
        if (!values.influencerDetails) {
          values.influencerDetails = {};
        }
        values.influencerDetails.status = 'rejected';
        values.influencerDetails.fullName = obj.fullName;
        values.influencerDetails.id = obj.id;
        values.influencerDetails.instaEmail = obj.instaEmail;
        values.influencerDetails.whatsappNumber = obj.whatsappNumber;

        values.requestRejectReason = reason;
        msg = 'Influencer Request rejected!';
      }

      const noteRef = doc(db, 'users', id);
      await updateDoc(noteRef, values);
      console.log(fcmToken, 'fcmToken');

      const token = fcmToken;
      const title = 'Influencer request status';
      const body = 'Your request for influencer profile has been accepted! ';
      const payload: any = {};
      payload.roomId = obj.roomId;

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}send`,
          {
            message: {
              token,
              notification: {
                title,
                body
              },
              data: payload || {}
            }
          },
          {
            headers: {
              'Content-Type': 'application/json'
            },
          }
        );

        console.log('Notification sent successfully:', response.data.success);
      } catch (error: any) {
        console.error('Error sending notification:', error.message);
      }

      console.log(valuesNotify, 'valuesNotify');

      await setDoc(doc(db, 'notifications', uuid), valuesNotify);

      //       Ye m send krunga apko type m
      // influencerAccepted
      // influencerRejected
      // roomRequest
      // eventRequest

      addNotification({
        type: 'success',
        title: 'Success',
        message: msg,
      });
      getUsers();
      setLoad(false);
    } catch (e: any) {
      addNotification({
        type: 'error',
        title: 'Failure',
        message: e.message ?? 'There was an error accepting Request!',
      });
    } finally {
      setLoad(false);
    }
  };
  return (
    <>
      <Table
        data={users}
        columns={[
          {
            title: 'Image',
            field: 'medias',
            Cell({ entry: { medias } }) {
              return (
                <img
                  src={medias ?? placeholder}
                  style={{ width: '50px', height: '50px', objectFit: 'cover' }}
                  onError={(e: any) => {
                    e.target.src = placeholder;
                  }}
                />
              );
            },
          },
          {
            title: 'Name',
            field: 'influencerDetails',
            Cell({ entry: { influencerDetails } }) {
              return <span>{influencerDetails?.fullName}</span>;
            },
          },
          {
            title: 'Email',
            field: 'influencerDetails',
            Cell({ entry: { influencerDetails } }) {
              return <span>{influencerDetails?.instaEmail}</span>;
            },
          },
          {
            title: 'Whatsapp Number',
            field: 'influencerDetails',
            Cell({ entry: { influencerDetails } }) {
              return <span>{influencerDetails?.whatsappNumber}</span>;
            },
          },
          {
            title: 'Status',
            field: 'influencerStatus',
            Cell({ entry: { influencerStatus } }) {
              return (
                <div>
                  {influencerStatus === 'accepted' ? (
                    <p
                      className="me-2"
                      // onClick={() => navigate(`/app/user/view/${id}`)}
                    >
                      <i className="fa-solid fa-check"></i> Accepted
                    </p>
                  ) : (
                    <>
                      {influencerStatus === 'rejected' ? (
                        <p
                          className="me-2"
                          // onClick={() => navigate(`/app/user/view/${id}`)}
                        >
                          <i className="fa-solid fa-hourglass-half"></i> Rejected
                        </p>
                      ) : (
                        <p
                          className="me-2"
                          // onClick={() => navigate(`/app/user/view/${id}`)}
                        >
                          <i className="fa-solid fa-hourglass-half"></i> Pending
                        </p>
                      )}
                    </>
                  )}
                </div>
              );
            },
          },
          {
            title: 'Actions',
            field: 'id',
            Cell({ entry: { id, influencerStatus, influencerDetails, fcmToken } }) {
              return (
                <>
                  <div className="row">
                    <div className="col-md-6 kill-left">
                      <ConfirmationDialog
                        triggerButton={
                          <Button
                            tabIndex={-1}
                            className="icon me-2"
                            variant="outline"
                            title="Accept Request"
                            disabled={influencerStatus != 'requested'}
                            isLoading={load}
                          >
                            <i className="fa-solid fa-check"></i>
                          </Button>
                        }
                        confirmButton={
                          <>
                            <Button
                              size="sm"
                              variant="primary"
                              className="expanded-btn actPop"
                              onClick={() =>
                                handleInfluencer(id, 'accepted', influencerDetails, fcmToken)
                              }
                            >
                              Accept
                            </Button>
                          </>
                        }
                        title="Accept Request"
                        icon="info"
                        body="Are you sure you want to accept this request ?"
                      />
                    </div>
                    <div className="col-md-6 kill">
                      <ConfirmationDialog
                        triggerButton={
                          <Button
                            tabIndex={-1}
                            className="icon me-2"
                            variant="outline"
                            title="Reject Request"
                            disabled={influencerStatus != 'requested'}
                            isLoading={load}
                          >
                            <i className="fa-solid fa-x"></i>
                          </Button>
                        }
                        deleteButton={<>Reject</>}
                        title="Reject Request"
                        icon="danger"
                        body="Are you sure you want to reject this request ?"
                        bodyData={true}
                        onDataConfirm={(values) => {
                          handleInfluencer(id, 'rejected', influencerDetails, values);
                        }}
                      />
                    </div>
                  </div>
                </>
              );
            },
          },
        ]}
        rowsPerPageOptions={rowsPerPageOptions}
      />
    </>
  );
};
