import { useNavigate } from 'react-router-dom';

import { RegisterLayout } from '../components/RegisterLayout';
import { ResetForm } from '../components/ResetForm';

export const ResetPassword = () => {
  const navigate = useNavigate();

  return (
    <RegisterLayout title="Forgot Password">
      <ResetForm onSuccess={() => navigate('/app')} />
    </RegisterLayout>
  );
};
