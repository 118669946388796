import { useNavigate } from 'react-router-dom';

import { RegisterForm } from '../components/RegisterForm';
import { RegisterLayout } from '../components/RegisterLayout';

export const Register = () => {
  const navigate = useNavigate();

  return (
    <RegisterLayout title="Register">
      <RegisterForm onSuccess={() => navigate('/app')} />
    </RegisterLayout>
  );
};
