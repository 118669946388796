import { doc, getDoc, DocumentData } from '@firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import placeholder from '@/assets/placeholder.jpg';
import BackdropLoader from '@/components/BackdropLoader';
import { db } from '@/lib/firestore';

function RoomDetail() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [event, setEvent] = useState<Event | DocumentData | null>();

  //   const { user } = useAuth();
  const setDocument = async (id: string) => {
    setLoading(true);
    const noteSnapshot = await getDoc(doc(db, 'rooms', id));
    if (noteSnapshot.exists()) {
      const eventData = noteSnapshot.data();
      setEvent(eventData);
    }
    setTimeout(() => {
      setLoading(false);
    }, 0);
  };

  useEffect(() => {
    if (id) {
      setDocument(id);
    } else {
      setLoading(false);
    }
  }, [id]);
  console.log(event, 'event');

  return (
    <>
      {loading ? (
        <BackdropLoader open={true} />
      ) : (
        <>
          <div className="row mt-4">
            <div className="col-md-3">
              <p>
                {' '}
                <img
                  src={event?.image ?? placeholder}
                  style={{
                    width: '160px',
                    height: '160px',
                    borderRadius: '500px',
                    objectFit: 'cover',
                  }}
                  onError={(e: any) => {
                    e.target.src = placeholder;
                  }}
                />
              </p>
            </div>
            <div className="col-md-9">
              <p>
                <span className="evntSpan">Title</span>
                {event.title}
              </p>
              <p>
                <span className="evntSpan">Address</span>
                {event.address}
              </p>
              <p>
                <span className="evntSpan">Description</span>
                {event.description}
              </p>
              <p>
                <span className="evntSpan">Room Type</span>
                {event.roomType ? event.roomType : "Party"}
              </p>
            </div>
          </div>
          {event?.users?.length > 0 && (
            <>
              <hr />
              <p>
                <span className="evntSpan">Joined Member</span>
              </p>
            </>
          )}
          <div className="row">
            {event?.users?.map((item, index) => (
              <div className="col-md-2 " key={index}>
                <div className="profBox">
                  <img
                    src={item?.medias[0] ?? placeholder}
                    style={{
                      width: '80px',
                      height: '80px',
                      borderRadius: '500px',
                      objectFit: 'cover',
                    }}
                    onError={(e: any) => {
                      e.target.src = placeholder;
                    }}
                  />
                  <span className="evntSpan"> {item.name}</span>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
}

export default RoomDetail;
