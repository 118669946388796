import { ContentLayout } from '@/components/Layout';

import { Button } from '@/components/Elements';
import { ReportList } from '../components/ReportList';

export const Reports = () => {
    return (
        <ContentLayout title="Professionals">
            <div className="m-3">
                <ReportList />
            </div>
        </ContentLayout>
    )
}