import { useNavigate } from 'react-router-dom';

import { ContentLayout } from '@/components/Layout';

import { InfluencerList } from '../components/InfluencerList';

export const InfluencerRequest = () => {
  const navigate = useNavigate();

  return (
    <ContentLayout title="Rooms">
      <div className="m-3">
        <div className="d-flex align-content-center justify-content-between mb-3">
          <h3>Influencer Request List</h3>
        </div>
        <InfluencerList />
      </div>
    </ContentLayout>
  );
};
