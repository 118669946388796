import { collection, doc, getDocs, query, where } from 'firebase/firestore';
import { useEffect, useState } from 'react';

import { ContentLayout } from '@/components/Layout';
import { useAuth } from '@/lib/auth';
import { ROLES } from '@/lib/authorization';
import { db } from '@/lib/firestore';
import './dashboard.css';

export const Dashboard = () => {
  const { user } = useAuth();
  const [eventCount, setEventCount] = useState<Number | null>(null);
  const [userCount, setUserCount] = useState<Number | null>(null);
  const [roomCount, setRoomCount] = useState<Number | null>(null);

  const dashboardData = async () => {
    try {
      if (user?.role !== 'Professional') {
        const eventsSnapshotEvents = await getDocs(collection(db, 'events'));
        setEventCount(eventsSnapshotEvents.size ?? 0);
        const eventsSnapshotUsers = await getDocs(collection(db, 'users'));
        setUserCount(eventsSnapshotUsers.size ?? 0);
        const eventsSnapshotRooms = await getDocs(collection(db, 'rooms'));
        setRoomCount(eventsSnapshotRooms.size ?? 0);
      } else {
        const userRef = doc(db, 'professionals', user?.id);
        const roomsSnapshot = await getDocs(
          query(collection(db, 'rooms'), where('createdBy', '==', userRef))
        );
        setRoomCount(roomsSnapshot.size ?? 0);
        const eventsSnapshot = await getDocs(
          query(collection(db, 'events'), where('createdBy', '==', userRef))
        );
        setEventCount(eventsSnapshot.size ?? 0);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      setRoomCount(0);
      setEventCount(0);
    }
  };

  useEffect(() => {
    dashboardData();
  }, []);

  return (
    <ContentLayout title="Dashboard">
      <div className="pagetitle">
        <h1 className="headingCol">Dashboard</h1>
        {/* <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="index.html">Home</a>
            </li>
            <li className="breadcrumb-item active">Dashboard</li>
          </ol>
        </nav> */}
      </div>
      {/* End Page Title */}
      <section className="section dashboard">
        <div className="row">
          {/* Left side columns */}
          <div className="col-lg-8">
            <div className="row">
              {/* Revenue Card */}
              <div className="col-xxl-4 col-md-4">
                <div className="card info-card revenue-card">
                  {/* <div className="filter">
                    <a className="icon" href="#" data-bs-toggle="dropdown">
                      <i className="bi bi-three-dots" />
                    </a>
                    <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                      <li className="dropdown-header text-start">
                        <h6>Filter</h6>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Today
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          This Month
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          This Year
                        </a>
                      </li>
                    </ul>
                  </div> */}
                  <div className="card-body">
                    <h5 className="card-title headingCol">Events</h5>
                    <div className="d-flex align-items-center">
                      <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <i className="bi bi-currency-dollar" />
                      </div>
                      <div className="ps-3">
                        <h6 className="loading-indicator textCol">
                          {eventCount !== null ? (
                            eventCount
                          ) : (
                            <div className="col-3 ml-2">
                              <div className="snippet" data-title="dot-flashing">
                                <div className="stage">
                                  <div className="dot-flashing"></div>
                                </div>
                              </div>
                            </div>
                          )}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Revenue Card */}
              {/* Customers Card */}
              {user?.role !== 'Professional' && (
                <div className="col-xxl-4 col-4">
                  <div className="card info-card customers-card">
                    <div className="card-body">
                      <h5 className="card-title headingCol">Users</h5>
                      <div className="d-flex align-items-center">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                          <i className="bi bi-people" />
                        </div>
                        <div className="ps-3">
                          <h6 className="loading-indicator textCol">
                            {userCount !== null ? (
                              userCount
                            ) : (
                              <div className="col-3 ml-2">
                                <div className="snippet" data-title="dot-flashing">
                                  <div className="stage">
                                    <div className="dot-flashing"></div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* End Customers Card */}

              <div className="col-xxl-4 col-4">
                <div className="card info-card customers-card">
                  <div className="card-body">
                    <h5 className="card-title headingCol">Rooms</h5>
                    <div className="d-flex align-items-center">
                      <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <i className="bi bi-people" />
                      </div>
                      <div className="ps-3">
                        <h6 className="loading-indicator textCol">
                          {roomCount !== null ? (
                            roomCount
                          ) : (
                            <div className="col-3 ml-2">
                              <div className="snippet" data-title="dot-flashing">
                                <div className="stage">
                                  <div className="dot-flashing"></div>
                                </div>
                              </div>
                            </div>
                          )}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Reports */}

              {/* End Top Selling */}
            </div>
          </div>
          {/* End Left side columns */}
          {/* Right side columns */}

          {/* End Right side columns */}
        </div>
      </section>
    </ContentLayout>
  );
};
