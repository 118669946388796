import { collection, deleteDoc, doc, getDocs, query, where } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import placeholder from '@/assets/placeholder.jpg';
import {
  Spinner,
  Button,
  Table,
  Dialog,
  DialogTitle,
  ConfirmationDialog,
} from '@/components/Elements';
import { ContentLayout } from '@/components/Layout';
import { useAuth } from '@/lib/auth';
import { db } from '@/lib/firestore';
import { useNotificationStore } from '@/stores/notifications';

import { Room } from '../types';
import { TextField } from '@mui/material';

export const RoomsList = () => {
  const { user } = useAuth();
  const userRole = user?.role;
  const [loading, setLoading] = useState<boolean>(false);
  const [rooms, setRooms] = useState<Room[]>([]);
  const [filteredRooms, setFilteredRooms] = useState<Room[]>([]);
  const [search, setSearch] = useState<string>('');
  const navigate = useNavigate();
  const { addNotification } = useNotificationStore();
  const rowsPerPageOptions = [10, 25, 50, 100];
  const [page, setPage] = useState(0);
  const [deleteLoader, setDeleteLoader] = useState<boolean>(false);

  const getRooms = async () => {
    setLoading(true);
    const userId = user?.id;
    try {
      let q = collection(db, 'rooms');
      if (userId && user?.role !== 'Admin') {
        const userRef = doc(db, 'professionals', userId);
        q = query(q, where('createdBy', '==', userRef));
      }

      const roomsSnapshot = await getDocs(q);
      const roomList = roomsSnapshot.docs.map((doc) => doc.data() as Room);
      setRooms(roomList);
      setFilteredRooms(roomList);
    } catch (error) {
      console.error('Error fetching rooms:', error);
      setRooms([]);
      setFilteredRooms([]);
    }
    setLoading(false);
  };

  const handleDelete = async (roomId: string) => {
    try {
      setDeleteLoader(true);
      const noteRef = doc(db, 'rooms', roomId!);
      await deleteDoc(noteRef);
      addNotification({
        type: 'success',
        title: 'Success',
        message: 'Room deleted successfully!',
      });
      setRooms((prevRooms) => prevRooms.filter((room) => room.id !== roomId));
      setFilteredRooms((prevFilteredRooms) => prevFilteredRooms.filter((room) => room.id !== roomId));
    } catch (e: any) {
      addNotification({
        type: 'error',
        title: 'Failure',
        message: e.message ?? 'There was an error deleting the room!',
      });
    } finally {
      setDeleteLoader(false);
    }
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value.toLowerCase();
    setSearch(query);

    const filtered = rooms.filter(room =>
      room.title.toLowerCase().includes(query) ||
      room.description.toLowerCase().includes(query)
    );
    setFilteredRooms(filtered);
    setPage(0); // Reset to page 0
  };

  useEffect(() => {
    getRooms();
  }, []);

  if (loading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  return (
    <ContentLayout title="Rooms">
      <div className="mb-4">
        <TextField
          id="search-bar"
          label="Search by Name or Description"
          variant="outlined"
          value={search}
          onChange={handleSearch}
          sx={{ width: '250px' }}
        />
      </div>
      <Table
        data={filteredRooms}
        columns={[
          {
            title: 'Image',
            field: 'image',
            Cell({ entry: { image } }) {
              return (
                <img
                  src={image ?? placeholder}
                  style={{ width: '50px', height: '50px', objectFit: 'cover' }}
                  onError={(e: any) => {
                    e.target.src = placeholder;
                  }}
                />
              );
            },
          },
          {
            title: 'Title',
            field: 'title',
          },
          {
            title: 'Type',
            field: 'roomType',
            Cell({ entry: { roomType } }) {
              return (
                <span>{roomType ?? "Party"}</span>
              );
            }
          },
          {
            title: 'Description',
            field: 'description',
            Cell({ entry: { description } }) {
              return (
                <>
                  <p className="dicsr">{description}</p>
                </>
              );
            },
          },
          {
            title: 'Actions',
            field: 'id',
            Cell({ entry: { id } }) {
              return (
                <div>
                  {userRole === 'Admin' ? null : (
                    <>
                      <Button
                        className="me-2"
                        variant="outline"
                        onClick={() => navigate(`/app/room/edit-room/${id}`)}
                      >
                        <i className="fa fa-pencil" />
                      </Button>
                      <ConfirmationDialog
                        triggerButton={
                          <Button
                            tabIndex={-1}
                            className="icon me-2"
                            variant="outline"
                            title="Delete"
                          >
                            <i className="fa fa-trash" />
                          </Button>
                        }
                        confirmButton={
                          <Button
                            variant="primary"
                            className="expanded-btn actPop"
                            onClick={() => handleDelete(id)}
                            isLoading={deleteLoader}
                          >
                            Delete
                          </Button>
                        }
                        title="Delete Room"
                        icon="danger"
                        body="Are you sure you want to delete this room?"
                      />
                    </>
                  )}
                  <Button
                    className="me-2"
                    variant="outline"
                    onClick={() => navigate(`/app/room-detail/${id}`)}
                  >
                    <i className="fa-solid fa-eye"></i>
                  </Button>
                </div>
              );
            },
          },
        ]}
        rowsPerPageOptions={rowsPerPageOptions}
      />
    </ContentLayout>
  );
};
