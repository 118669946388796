import { sendPasswordResetEmail } from 'firebase/auth';
import { useState } from 'react';
import * as z from 'zod';

import logo from '@/assets/logo.jpg';
import { Button } from '@/components/Elements';
import { Form, InputField } from '@/components/Form';
import '../routes/auth.css';
import { auth } from '@/lib/firestore';
import { useNotificationStore } from '@/stores/notifications';

import { useNavigate } from 'react-router-dom';

const schema = z.object({
  email: z.string().min(1, 'Email address is required').email('Please enter a valid email address'),
});

type RegisterValues = {
  email: string;
};

type RegisterFormProps = {
  onSuccess: () => void;
};

export const ResetForm = ({ onSuccess }: RegisterFormProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { addNotification } = useNotificationStore();
  const navigate = useNavigate();

  const handleSubmit = async (emails: string) => {
    setLoading(true);
    try {
      const email = emails.email;
      await sendPasswordResetEmail(auth, email);

      addNotification({
        type: 'success',
        title: 'Password Reset Email Sent',
        message: 'Please check your email to reset your password.',
      });
      navigate('/auth/login');
      setLoading(false);
    } catch (error) {
      setLoading(false);
      addNotification({
        type: 'error',
        title: 'Failure',
        message: 'Invalid email !',
      });
    }
  };

  return (
    <main>
      <div className="container">
        <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
                <div className="d-flex justify-content-center py-4">
                  <a href="index.html" className="logo d-flex align-items-center w-auto">
                    <img src={logo} style={{ maxHeight: '100px' }} alt="" />
                    <span className="d-none d-lg-block"></span>
                  </a>
                </div>
                {/* End Logo */}
                <div className="card mb-3">
                  <div className="card-body">
                    <div className="pt-4 pb-2">
                      <h5 className="card-title text-center pb-0 fs-4">Reset password</h5>
                      <p className="text-center small">Enter your email address</p>
                    </div>
                    <Form<RegisterValues, typeof schema>
                      onSubmit={handleSubmit}
                      schema={schema}
                      className="row g-3 needs-validation"
                    >
                      {({ register, formState }) => (
                        <>
                          <InputField
                            type="email"
                            label="Email Address"
                            error={formState.errors['email']}
                            registration={register('email')}
                          />

                          <div className="col-12">
                            <Button
                              isLoading={loading}
                              className="btn btn-primary w-100"
                              type="submit"
                            >
                              Reset
                            </Button>
                          </div>
                        </>
                      )}
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
};
