import { signInWithEmailAndPassword, getAuth, onAuthStateChanged } from 'firebase/auth';
import { Link } from 'react-router-dom';
import * as z from 'zod';

import logo from '@/assets/logo.jpg';
import { Button } from '@/components/Elements';
import { Form, InputField } from '@/components/Form';
import { auth } from '@/lib/firestore';

import '../routes/auth.css';
import { useState } from 'react';

import { useNotificationStore } from '@/stores/notifications';
import { FIREBASE_ERROR } from '@/utils/constants';

const schema = z.object({
  email: z.string().min(1, 'Email address is required'),
  password: z
    .string()
    .min(1, 'Password is required')
    .min(6, 'Password must contain atleast 6 characters'),
});

type LoginValues = {
  email: string;
  password: string;
};

type LoginFormProps = {
  onSuccess: () => void;
};

export const LoginForm = ({ onSuccess }: LoginFormProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { addNotification } = useNotificationStore();

  const handleSubmit = async (values: LoginValues) => {
    setLoading(true);
    const { email, password } = values;
    signInWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        const user = userCredential.user;
        setLoading(false);
      })
      .catch((error) => {
        const errorCode = FIREBASE_ERROR[error.code] ?? error.code;
        setLoading(false);
        addNotification({
          type: 'error',
          title: 'Failure',
          message: errorCode,
        });
      });
    // onSuccess();
  };

  return (
    <main>
      <div className="container">
        <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
                <div className="d-flex justify-content-center py-4">
                  <a href="index.html" className="logo d-flex align-items-center w-auto">
                    <img src={logo} style={{ maxHeight: '100px' }} alt="" />
                    <span className="d-none d-lg-block"></span>
                  </a>
                </div>
                {/* End Logo */}
                <div className="card mb-3">
                  <div className="card-body loginCards">
                    <div className="pt-4 pb-2">
                      <h5 className="card-title text-center pb-0 fs-4">Login to Your Account</h5>
                      <p className="text-center small">Enter your email &amp; password to login</p>
                    </div>
                    <Form<LoginValues, typeof schema>
                      onSubmit={handleSubmit}
                      className="row g-3 needs-validation"
                      schema={schema}
                    >
                      {({ register, formState }) => (
                        <>
                          <div className="col-12">
                            <InputField
                              type="email"
                              label="Email Address"
                              error={formState.errors['email']}
                              registration={register('email')}
                            />
                            <div className="mt-3">
                              <InputField
                                type="password"
                                label="Password"
                                error={formState.errors['password']}
                                registration={register('password')}
                              />
                            </div>
                          </div>
                          <div className="col-12">
                            <Button
                              isLoading={loading}
                              className="btn btn-primary w-100"
                              type="submit"
                            >
                              Login
                            </Button>
                          </div>
                          {/* <div className="col-12">
                            <p className="small mb-0 crScnt">
                              Don't have account? <Link to="/auth/register">Create an account</Link>
                            </p>
                          </div> */}
                          <div className="col-12">
                            <p className="small mb-0 crScnt">
                              <Link to="/auth/reset">Forgot password ? </Link>
                            </p>
                          </div>
                        </>
                      )}
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
};
