import { onAuthStateChanged, signOut } from 'firebase/auth';
import { doc, getDoc, getDocs, query, where, collection } from 'firebase/firestore';
import { useEffect } from 'react';
import { Navigate, useLocation, useNavigate, useRoutes } from 'react-router-dom';

import { Landing } from '@/features/misc';
import { useAuth } from '@/lib/auth';
import { auth, db } from '@/lib/firestore';
import { useNotificationStore } from '@/stores/notifications';

import { protectedRoutes } from './protected';
import { publicRoutes } from './public';

const adminsCollection = collection(db, 'admins');
const profCollection = collection(db, 'professionals');

export const AppRoutes = () => {
  const { user } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const { login } = useAuth();
  const { addNotification } = useNotificationStore();

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User

        const querySnapshot = await getDocs(
          query(adminsCollection, where('email', '==', user.email))
        );
        const querySnapshotProf = await getDocs(
          query(profCollection, where('email', '==', user.email))
        );
        if (querySnapshotProf.size > 0) {
          const doc = querySnapshotProf.docs[0].data();
          if (!doc.isActive) {
            signOut(auth);
            addNotification({
              type: 'error',
              title: 'Error',
              message: 'User blocked by Admin!',
            });
            return;
          }
        }
        if (querySnapshot.size === 0 && querySnapshotProf.size === 0) {
          // handle redirect
          signOut(auth);
          addNotification({
            type: 'error',
            title: 'Error',
            message: 'User does not exists!',
          });
          return;
        }

        let userObj = null;
        if (querySnapshot.size > 0) {
          querySnapshot.forEach((doc) => {
            userObj = doc.data();
            userObj.role = 'Admin';
            // Do something with userData
          });
        }
        if (querySnapshotProf.size > 0) {
          querySnapshotProf.forEach((doc) => {
            userObj = doc.data();
            userObj.role = 'Professional';
            // Do something with userData
          });
        }
        if (userObj) {
          login(userObj);
          if (!location.pathname.includes('app')) {
            navigate('/app');
            console.log(location);
          }
        }
        // ...
      } else {
        // User is signed out
        // ...
      }
    });
  }, []);

  const commonRoutes = [{ path: '/', element: <Navigate to={user ? '/app' : '/auth/login'} /> }];

  const routes = user ? protectedRoutes : publicRoutes;

  const element = useRoutes([...routes, ...commonRoutes]);

  return <>{element}</>;
};
