import { collection, getDocs } from 'firebase/firestore';
import { useEffect, useState } from 'react';

import placeholder from '@/assets/placeholder.jpg';
import { Spinner, Table } from '@/components/Elements';
import { ContentLayout } from '@/components/Layout';
import { db } from '@/lib/firestore';

export const ReportList = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [reports, setReports] = useState<[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const rowsPerPageOptions = [10, 25, 50, 100];

    const getReports = async () => {
        setLoading(true);
        const reportsSnapshot = await getDocs(collection(db, 'reports'));
        const reportList = reportsSnapshot.docs.map((doc) => doc.data());
        setReports(reportList);
        setLoading(false);
    };

    const filteredReports = reports.filter(
        (report) =>
            report.fromName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            report.toName.toLowerCase().includes(searchQuery.toLowerCase())
    );

    useEffect(() => {
        getReports();
    }, []);

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    };

    if (loading) {
        return (
            <div className="w-full h-48 flex justify-center items-center">
                <Spinner size="lg" />
            </div>
        );
    }

    return (
        <ContentLayout title="Reports">
            <div className="row mb-2">
                <div className="col-6">
                    <input
                        type="text"
                        value={searchQuery}
                        onChange={handleSearch}
                        placeholder="Search a person"
                        className="form-control"
                    />
                </div>
            </div>
            <Table
                data={filteredReports}
                columns={[
                    {
                        title: 'Reported By',
                        field: 'fromName',
                    },
                    {
                        title: 'Reported User',
                        field: 'toName',
                    },
                ]}
                rowsPerPageOptions={rowsPerPageOptions}
            />
        </ContentLayout>
    );
};
