import { Link, useParams } from 'react-router-dom';
import * as z from 'zod';
import logo from '@/assets/logo.jpg';
import { Button } from '@/components/Elements';
import { Form, InputField } from '@/components/Form';
import '../routes/auth.css';
import { useEffect } from 'react';
import { RadioButtonField } from '@/components/Form/RadioButtonField';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from '@/lib/firestore';
import { useState } from 'react';
import { useNotificationStore } from '@/stores/notifications';
import { doc, setDoc, collection, getDoc, updateDoc, getDocs } from 'firebase/firestore';
import { db } from '@/lib/firestore';
import { default as dayjs } from 'dayjs';
import { FIREBASE_ERROR } from '@/utils/constants';

const schema = z.object({
  name: z.string().min(1, 'Name is required'),
  email: z.string().min(1, 'Email address is required').email('Please enter a valid email address'),
  password: z.string().min(1, 'Password is required'),
  terms: z.literal(true, {
    errorMap: () => ({ message: 'You must agree before submitting.' }),
  }),
});

type RegisterValues = {
  name: string;
  email: string;
  password: string;
  terms: string;
};

type RegisterFormProps = {
  onSuccess: () => void;
};

export const RegisterForm = ({ onSuccess }: RegisterFormProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { role } = useParams();
  const { addNotification } = useNotificationStore();

  const handleSubmit = async (values: RegisterValues) => {
    const { email, password, name } = values;
    let userRole = role ? role : 'admin';
    setLoading(true);
    createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        // Signed in
        const user = userCredential.user;
        setLoading(false);
        await setDoc(doc(db, 'User', user.uid), {
          id: user.uid,
          name: name,
          email: email,
          role: userRole,
          dateTime: dayjs().format(),
          emailVerified: userRole === 'admin' ? 'approved' : 'pending',
        });
      })
      .catch((error) => {
        const errorCode = FIREBASE_ERROR[error.code] ?? error.code;
        setLoading(false);
        addNotification({
          type: 'error',
          title: 'Failure',
          message: errorCode,
        });
      });
  };
  return (
    <main>
      <div className="container">
        <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
                <div className="d-flex justify-content-center py-4">
                  <a href="index.html" className="logo d-flex align-items-center w-auto">
                    <img src={logo} style={{ maxHeight: '100px' }} alt="" />
                    <span className="d-none d-lg-block"></span>
                  </a>
                </div>
                {/* End Logo */}
                <div className="card mb-3">
                  <div className="card-body">
                    <div className="pt-4 pb-2">
                      <h5 className="card-title text-center pb-0 fs-4">Create an Account</h5>
                      <p className="text-center small">
                        Enter your personal details to create account
                      </p>
                    </div>
                    <Form<RegisterValues, typeof schema>
                      onSubmit={handleSubmit}
                      schema={schema}
                      className="row g-3 needs-validation"
                    >
                      {({ register, formState }) => (
                        <>
                          <InputField
                            type="text"
                            label="Name"
                            error={formState.errors['name']}
                            registration={register('name')}
                          />
                          <InputField
                            type="email"
                            label="Email Address"
                            error={formState.errors['email']}
                            registration={register('email')}
                          />
                          <InputField
                            type="password"
                            label="Password"
                            error={formState.errors['password']}
                            registration={register('password')}
                          />
                          <RadioButtonField
                            type="checkbox"
                            label={
                              <p>
                                I agree and accept the <a href="#">terms and conditions</a>
                              </p>
                            }
                            error={formState.errors['terms']}
                            registration={register('terms')}
                          />

                          <div className="col-12">
                            <Button
                              isLoading={loading}
                              className="btn btn-primary w-100"
                              type="submit"
                            >
                              Create Account
                            </Button>
                          </div>
                          <div className="col-12">
                            <p className="small mb-0">
                              Already have an account? <Link to="/auth/login">Log in</Link>
                            </p>
                          </div>
                        </>
                      )}
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
};
