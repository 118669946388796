import clsx from 'clsx';
import { UseFormRegisterReturn, Control, FieldValues } from 'react-hook-form';
import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';

type Option = {
  label: React.ReactNode;
  value: string | number | string[];
};

type SelectFieldProps = FieldWrapperPassThroughProps &
  React.SelectHTMLAttributes<HTMLSelectElement> & {
    options: Option[];
    className?: string;
    defaultValue?: string;
    placeholder?: string;
    registration?: Partial<UseFormRegisterReturn>; // Updated the type of registration
    disbaled?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    value?: string | number | string[];
    control?: Control<FieldValues> | undefined;
  };

export const SelectField = (props: SelectFieldProps) => {
  const {
    label,
    options,
    error,
    className,
    defaultValue,
    registration,
    placeholder,
    onChange,
    value,
    control,
    disabled,
  } = props;

  return (
    <FieldWrapper label={label} error={error}>
      <select
        placeholder={placeholder}
        name="location"
        className={clsx('form-select', className)}
        defaultValue={defaultValue}
        onChange={onChange}
        disabled={disabled}
        value={value}
        {...(registration as any)} // Cast registration to any
        {...(control ? { ...control } : {})} // Spread control only if it exists
      >
        {options.map(({ label, value }) => (
          <option key={label?.toString()} value={value}>
            {label}
          </option>
        ))}
      </select>
      <span className="line" />
    </FieldWrapper>
  );
};
